<template>
  <div>
    <Page :isLoading="loading" v-if="lead">
      <EditModal ref="editModal" :config="activeEditConfig" :object="lead" @save="handleSave" />
      <span slot="title">{{ title }}</span>
      <div slot="actionbar">
        <div class="btn-group">
          <a @click="previousStage" class="btn btn-success" v-if="lead.previous_stage">
            <i class="glyphicon glyphicon-backward" /> Vorige fase
          </a>

          <div class="btn-group">
            <button aria-expanded="false" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
              Acties <i class="caret" />
            </button>
            <ul class="dropdown-menu">
              <li><a title="Toewijzen" @click="showAssignModal">Toewijzen</a></li>
              <li><a title="Delen" @click="showShareModal">Delen</a></li>
              <li v-if="lead.status !== 2">
                <a title="Break" @click="showBreakModal">Breaken</a>
              </li>
              <li class="divider" />
              <li><a title="Bewerken" @click="editLead">Bewerken</a></li>
              <li><a title="Verwijderen" @click="removeLead">Verwijderen</a></li>
              <template v-if="lead.status !== 3">
                <li class="divider" />
                <li><a title="Naar deal" @click="changeStatus(3)">Naar deal</a></li>
              </template>
            </ul>
          </div>

          <button @click="showNewActivityModal" class="btn btn-primary">
            <i class="glyphicon glyphicon-plus-sign" /> Activiteit
          </button>

          <a v-if="lead.next_stage" class="btn btn-success" @click="nextStage">
            <i class="glyphicon glyphicon-forward" /> Volgende fase
          </a>
          <a v-else-if="lead.status !== 3" class="btn btn-success" @click="changeStatus(3)">
            <i class="glyphicon glyphicon-forward" /> Deal
          </a>
        </div>
      </div>

      <div v-if="leadIds" class="tw-flex tw-w-full tw-justify-end tw-flex-wrap tw-gap-2">
        <router-link
          :disabled="!leadIds.prev"
          :to="{ name: 'LeadDetails', params: { id: leadIds.prev || lead.id } }"
          :class="['btn btn-default', { 'tw-pointer-events-none': !leadIds.prev }]"
        >
          <i class="far fa-chevron-left" />
          Vorige
        </router-link>
        <router-link
          :disabled="!leadIds.next"
          :to="{ name: 'LeadDetails', params: { id: leadIds.next || lead.id } }"
          :class="['btn btn-default', { 'tw-pointer-events-none': !leadIds.next }]"
        >
          Volgende
          <i class="far fa-chevron-right" />
        </router-link>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="tabs-container">
            <ul class="nav nav-tabs">
              <li class="active">
                <a
                  aria-expanded="true"
                  data-toggle="tab"
                  href="#tab-timeline"
                  @click="tab = 'timeline'"
                >
                  <i class="fa fa-th-large" />
                  Historiek
                </a>
              </li>
              <li>
                <a
                  href="#tab-files"
                  data-toggle="tab"
                  aria-expanded="false"
                  @click="tab = 'files'"
                >
                  <i class="fa fa-file-text" />
                  {{ property ? 'Pandbestanden' : 'Projectbestanden' }}
                </a>
              </li>
              <li>
                <a
                  href="#tab-lead-files"
                  data-toggle="tab"
                  aria-expanded="false"
                  @click="tab = 'lead-files'"
                >
                  <i class="fa fa-file-text" />
                  Leadbestanden
                </a>
              </li>
              <li v-if="project || (property && [3, 11].includes(property.status))">
                <a
                  href="#tab-profile"
                  data-toggle="tab"
                  aria-expanded="false"
                  @click="tab = 'profile'"
                >
                  <i class="fa fa-user" />
                  Huurdersprofiel
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="tab-timeline">
                <transition name="fade" mode="out-in">
                  <div v-if="tab === 'timeline'" class="panel-body">
                    <div class="row">
                      <div class="col-sm-4">
                        <div class="ibox float-e-margins">
                          <div class="tw-flex tw-justify-between tw-p-3 tw-border tw-border-b-0 tw-bg-gray-50">
                            <span class="tw-inline-flex tw-items-center tw-font-semibold tw-rounded tw-border tw-px-2 tw-py-0.5 tw-bg-gray-500 tw-text-white tw-text-xs">{{ lead.status_display }}</span>
                            <button
                              v-if="shouldShowBidRegistrationButton"
                              title="Bodregistratie"
                              type="button"
                              class="btn btn-sm tw-bg-success !tw-text-white tw-py-0.5 tw-font-semibold"
                              @click="showPropertyBidsModal"
                            >
                              <i class="fas fa-gavel" /> Bodregistratie
                            </button>
                          </div>
                          <div>
                            <div v-if="lead.id" class="ibox-content no-padding border-left-right">
                              <ImageDropzone :imageUrl="lead.picture" :url="imageUploadUrl" />
                            </div>
                            <div class="ibox-content profile-content" v-if="entity">
                              <div class="row">
                                <div class="col-sm-6">
                                  <h4><strong>{{ lead.name }}</strong></h4>
                                </div>
                                <div class="col-sm-6">
                                  <button @click="editLead" class="pull-right btn btn-default btn-xs">
                                    <i class="fa fa-pencil" /> Bewerken
                                  </button>
                                </div>
                              </div>

                              <template v-if="entity.price && entity.price.price">
                                <i class="fa fa-euro" /> {{ entity.price.price|currency }}
                              </template>

                              <div v-if="entity">
                                <div>
                                  <i class="fa fa-map-marker" />
                                  {{ entity.address }}
                                  <EntityHoverable
                                    :value="{id: entity.id, reference: entity.reference, type: lead.entity_type}"
                                  />
                                  <div v-if="lead.project" class="tw-flex">
                                    <EntityHoverable
                                      :value="{id: lead.project.id, reference: lead.project.reference, type: 'project'}"
                                    />
                                    <span class="tw-inline-block tw-ml-1">(project)</span>
                                  </div>
                                </div>
                                <p v-if="entity.price">
                                  {{ entity.price|currency-verbose }}
                                  <small v-if="entity.GEMEENSCHAP_KOSTEN">
                                    ({{ entity.price.price|currency-verbose }} kosten)
                                  </small>
                                </p>
                                <p v-if="entity.visit_arrangement">
                                  <strong>Bezoekregeling: </strong> {{ entity.visit_arrangement }}
                                </p>
                              </div>

                              <h5 v-html="lead.description" />
                              <div class="tw-flex tw-gap-x-1 tw-mb-4">
                                <span class="badge badge-info" title="Leadbron">{{ lead.source_display }}</span>
                                <span class="badge badge-primary" v-if="lead.spontanuous">Spontaan</span>
                              </div>

                              <div v-if="lead.candidates" class="tw-my-6">
                                <h4 class="tw-my-0">Kandidaat</h4>
                                <ContactDisplayBox
                                  v-for="candidate in lead.candidates"
                                  type="candidate"
                                  :contact="candidate"
                                  :emailContext="emailContext"
                                  :key="`candidate_${candidate.id}`"
                                  class="tw-my-4"
                                />
                              </div>

                              <div v-if="entity" class="tw-my-6">
                                <h4 class="tw-my-0">Eigenaar</h4>
                                <ContactDisplayBox
                                  v-for="owner in owners"
                                  :contact="owner"
                                  :emailContext="emailContext"
                                  :key="`owner_${owner.id}`"
                                  class="tw-my-4"
                                />
                              </div>

                              <div class="row">
                                <hr>
                                <div class="col-sm-4 tw-my-2">
                                  <h4>Makelaar</h4>
                                  <div
                                    v-if="collaborator"
                                    :title="collaborator.display_name"
                                    class="tw-w-12 tw-my-4 tw-text-center"
                                  >
                                    <img
                                      :alt="collaborator.display_name"
                                      :src="userImage(collaborator)"
                                      class="img-circle"
                                    />
                                    {{ collaborator.display_name }}
                                  </div>
                                </div>
                                <div class="col-sm-8 tw-my-2">
                                  <h4>Volgers</h4>
                                  <div class="tw-flex tw-flex-wrap tw-gap-4">
                                    <div
                                      v-for="follower in followers"
                                      :key="follower.id"
                                      :title="follower.display_name"
                                      class="tw-w-12 tw-my-2 tw-text-center tw-relative"
                                    >
                                      <button
                                        title="Niet meer volgen"
                                        class="tw-px-1.5 tw-py-0.5 tw-absolute tw-right-0 tw-bg-white tw-transform tw--translate-y-2 tw-rounded-full tw-shadow hover:tw-shadow-xl active:tw-shadow"
                                        @click="unfollowLead(follower.id)"
                                      >
                                        <i class="far fa-trash-alt tw-text-error" />
                                      </button>
                                      <img
                                        :alt="follower.display_name"
                                        :src="userImage(follower)"
                                        class="img-circle tw-inline-block"
                                      />
                                      {{ follower.display_name }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">

                        <div class="row timeline-contents">
                          <div class="col-sm-12">
                            <div v-if="activitiesLoading" key="loading" class="tw-h-10 tw-text-center">
                              <i
                                class="fal fa-spinner-third fa-spin tw-text-3xl"
                              />
                            </div>
                            <div>
                              <div v-if="activities && activities.length" id="vertical-timeline" class="vertical-container light-timeline no-margins">
                                <ActivityTimelineItem
                                  ref="activityItems"
                                  v-for="activity in activities"
                                  :activity="activity"
                                  :key="activity.id"
                                  :show-details=activity.showDetails
                                  @activity-removed="activityRemoved"
                                  @editActivity="editActivity"
                                  @finish-activity="finishActivity"
                                  @send-confirmation="sendConfirmation"
                                  @reload-activities="reloadActivities"
                                />
                              </div>
                              <div v-else-if="!activitiesLoading" class="alert alert-info">
                                <p>Er zijn geen activiteiten voor deze lead gevonden.</p>
                                <p><a @click="showNewActivityModal">Nieuwe activiteit inplannen of afpunten?</a></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <PriceQuestion
                          v-if="shouldShowPriceQuestion"
                          :propertyId="entity.id"
                          :price="entity.price"
                        />
                        <div v-if="lead.candidate" class="tw-bg-gray-200 md:tw-mt-0 tw-mt-4 tw-pt-4 tw-px-4 tw-rounded tw-shadow-card">
                          <h3 class="tw-my-0 tw-font-bold tw-mb-2">Tags voor {{ lead.candidate.display_name }}</h3>
                          <ContactTagManager :contact-id="lead.candidate.id" />
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="tab-pane" id="tab-files">
                <transition name="fade" mode="out-in">
                  <div v-if="tab === 'files'" class="panel-body">
                    <EntityFilePanel
                      v-if="property || project"
                      :project="project"
                      :property="property"
                    />
                  </div>
                </transition>
              </div>
              <div class="tab-pane" id="tab-lead-files">
                <transition name="fade" mode="out-in">
                  <div v-if="tab === 'lead-files'" class="panel-body">
                    <LeadFilePanel
                      :lead-id="leadIdFromUrl"
                    />
                  </div>
                </transition>
              </div>
              <div
                v-if="project || (property && [3, 11].includes(property.status))"
                id="tab-profile"
                class="tab-pane"
              >
                <transition name="fade" mode="out-in">
                  <div v-if="tab === 'profile'" class="panel-body">
                    <RenterProfile :lead="lead.id" ref="renterProfile" />
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SMSConfirmationModal
        ref="smsModal"
        :lead="lead"
        @sms-sent="refreshLead"
      />
      <NewActivityModal
        ref="newActivityModal"
        v-if="collaborator"
        :lead="lead"
        :main-collaborator="collaborator"
        @activity-saved="activityChanged"
      />
      <ActivityFeedbackModal
        v-if="lead"
        ref="feedbackModal"
        :title="feedbackModalTitle"
        :lead="lead"
        :entity="entity"
        :activity="activity"
        :show-break-button="true"
        :show-details-button="false"
        :show-checkbox="feedbackModalCheckbox"
        @activity-finished="activityFinished"
        @messages-were-deleted="sendConfirmation"
      />
      <ActivityFeedbackModal
        ref="breakModal"
        v-if="lead"
        title="Lead breaken"
        :lead="lead"
        :entity="entity"
        :multi-mode="true"
        :show-break-button="true"
        :show-details-button="false"
        :show-finish-button="false"
        @finished="doBreakLead"
      />
      <LeadAssignment ref="leadAssignmentModal" :lead="lead" @leadAssigned="refreshLead" />
      <ShareWithCollaborator ref="shareModal" @share-with-collaborator="shareWithCollaborator" />
      <LeadBreakHmodhFormOpener ref="hmodhOpener" />
      <EditActivityModal ref="editActivityModal" @activity-changed="activityChanged" />
      <PropertyBidsModal
        ref="propertyBidsModal"
        v-if="lead"
        :lead-id="lead.id"
        :property-concept="entity.concept_type"
        :property="property"
        @bidAccepted="init"
      />
    </Page>
  </div>
</template>

<script>

import Page from '@/components/iam/Page'
import ContactDisplayBox from '@/components/contacts/ContactDisplayBox'
import ActivityTimelineItem from '@/components/sales/ActivityTimelineItem'
import NewActivityModal from '@/components/sales/NewActivityModal'
import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'
import ShareWithCollaborator from '@/components/sales/ShareWithCollaborator'
import ImageDropzone from '@/components/iam/ImageDropzone'
import EditModal from '@/components/iam/EditModal'
import EventBus from '@/components/iam/bus'
import SMSConfirmationModal from '@/components/sales/SMSConfirmationModal'
import EntityHoverable from '@/components/iam/EntityHoverable'
import ProfileImg from '@/assets/img/profile.png'
import EditActivityModal from '@/components/sales/EditActivityModal'
import PropertyBidsModal from '@/components/properties/PropertyBidsModal'
import LeadAssignment from '@/components/sales/LeadAssignment'

import LeadBreakHmodhFormOpener from '@/components/sales/LeadBreakHmodhFormOpener'

import { mapActions, mapGetters } from 'vuex'
import { questionModal, successModal, errorModal, startLoadingModal } from '@/modalMessages'
import { assignLead, breakLead, getLeadById, getLeadFollowers, removeLeadFollower, deleteLead, setLeadStatus, updateLead } from '@/services/sales'
import { getPropertyOwners } from '@/services/properties'
import { getProjectOwners } from '@/services/projects'

export default {
  name: 'Lead',
  components: {
    // Dynamic imports are called only when the components are asked to be rendered.
    // This improves render times, as unnecessary bloat is not included on first page load.
    LeadFilePanel: () => import(/* webpackChunkName: "LeadFilePanel" */ '@/components/sales/LeadFilePanel'),
    PriceQuestion: () => import(/* webpackChunkName: "PriceQuestion" */ '@/components/sales/PriceQuestion'),
    RenterProfile: () => import(/* webpackChunkName: "RenterProfile" */ '@/components/contacts/RenterProfile'),
    EntityFilePanel: () => import(/* webpackChunkName: "EntityFilePanel" */ '@/components/properties/EntityFilePanel'),
    ContactTagManager: () => import(/* webpackChunkName: "ContactTagManager" */ '@/components/contacts/ContactTagManager'),

    EntityHoverable,
    SMSConfirmationModal,
    ImageDropzone,
    ShareWithCollaborator,
    Page,
    ContactDisplayBox,
    ActivityTimelineItem,
    NewActivityModal,
    ActivityFeedbackModal,
    EditModal,
    LeadBreakHmodhFormOpener,
    EditActivityModal,
    PropertyBidsModal,
    LeadAssignment
  },
  data () {
    return {
      tab: 'timeline',
      loading: false,
      lead: null,
      activitiesLoading: true,
      activeEditConfig: {},
      showDetails: true,
      claimed: false,
      followers: [],
      feedbackModalTitle: 'Activiteit afpunten',
      activity: null,
      feedbackModalCheckbox: true,
      owners: [],
      leadIds: null,
      contact: null
    }
  },
  constants: {
    SALES_FUNNEL_ID: 2,
    RENTAL_FUNNEL_ID: 3,
    VISIT_ACTIVITY_TYPE_ID: 2,
    SMS_ACTIVITY_TYPE_ID: 119,
    OUTGOING_PHONE_CALL_ACTIVITY_TYPE_ID: 126
  },
  computed: {
    ...mapGetters('sales', ['getActivitiesForLeadById']),
    ...mapGetters('properties', ['getProjectById', 'getPropertyById']),
    ...mapGetters('organization', ['getCollaboratorById']),

    title () {
      return 'Leaddetails'
    },
    leadIdFromUrl () {
      return this.$route.params.id
    },
    entity () {
      if (!this.lead?.entity) return null
      return this.lead.entity_type === 'property'
        ? this.getPropertyById(this.lead.entity)
        : this.getProjectById(this.lead.entity)
    },
    property () {
      return this.lead?.entity_type === 'property'
        ? this.getPropertyById(this.lead.entity)
        : null
    },
    project () {
      return this.lead?.entity_type === 'project'
        ? this.getProjectById(this.lead.entity)
        : null
    },
    emailContext () {
      return this.lead?.entity_type === 'property'
        ? { properties: [this.entity] }
        : { project: this.entity }
    },
    entityUrl () {
      const params = { id: this.entity.id }
      if (!this.lead?.entity) return {}
      return this.lead?.entity_type === 'property'
        ? { name: 'PropertyDetails', params }
        : { name: 'ProjectDetails', params }
    },
    imageUploadUrl () {
      return `/api/sales/match/${this.lead.id}/picture`
    },
    editConfig () {
      return {
        fields: [
          {
            value: 'candidates',
            label: 'Kandidaat',
            placeholder: 'Kandidaat',
            type: 'contact',
            multiple: true,
            required: true
          },
          { value: 'name', label: 'Naam', placeholder: 'Naam', type: 'text' },
          { value: 'description', label: 'Omschrijving', placeholder: 'Omschrijving', type: 'textarea' },
          {
            value: 'spontanuous',
            label: 'Type',
            placeholder: '',
            type: 'radio',
            options: [
              { name: 'Spontaan', value: 'true' },
              { name: 'Prospect', value: 'false' }
            ]
          }
        ],
        title: 'Lead bewerken'
      }
    },
    activities () {
      return this.getActivitiesForLeadById(this.lead.id) || []
    },
    collaborator () {
      if (this.lead && this.lead.collaborator) {
        return this.getCollaboratorById(this.lead.collaborator)
      }
      return null
    },
    shouldShowPriceQuestion () {
      if (!this.property) return false
      const prospectStatus = 1
      return [null, 0, 1].includes(this.property.price) && this.property.status === prospectStatus
    },
    shouldShowBidRegistrationButton () {
      const leadIsSales = this.lead?.stage?.funnel === this.SALES_FUNNEL_ID || this.lead?.last_stage?.funnel === this.SALES_FUNNEL_ID
      const propertyIsForSaleOrAcquisition = [2, 4].includes(this.property?.status)
      return leadIsSales && propertyIsForSaleOrAcquisition
    }
  },
  watch: {
    lead () {
      if (this.$route.query?.claim && !this.claimed && this.collaborator) {
        this.claimLead()
      }
    }
  },
  created () {
    this.init()
  },
  beforeRouteLeave (to, from, next) {
    localStorage.setItem('lead_ids', null)
    next()
  },
  methods: {
    ...mapActions('sales', [
      'loadActivitiesForLead',
      'shareLead'
    ]),
    ...mapActions('organization', ['loadCollaboratorById']),
    ...mapActions('properties', ['loadProperty', 'loadProject']),

    getPrevAndNextLeadIds (leadId) {
      const leadIds = localStorage.getItem('lead_ids')?.split(',')
      if (leadIds?.length < 2) return null

      // Localstorage is a string, let's compare string ids.
      const currentLeadIdx = leadIds.indexOf(leadId.toString())
      const prev = leadIds[currentLeadIdx - 1]
      const next = leadIds[currentLeadIdx + 1]
      this.leadIds = { prev, next }
      return { prev, next }
    },
    editActivity (activityId) {
      this.$refs.editActivityModal.show(activityId)
    },
    async init () {
      try {
        const lead = await this.loadLead(this.$route.params.id)
        EventBus.$on('sales-reload-lead', this.refreshLead)

        const promises = [
          this.loadActivitiesForLead(lead.id),
          this.loadCollaboratorById(lead.collaborator),
          this.loadFollowersForLead(lead.id),
          this.fetchOwners(lead.entity),
          this.getPrevAndNextLeadIds(lead.id)
        ]

        if (this.lead?.entity_type === 'project') promises.push(this.loadProject(lead.entity))
        if (this.lead?.entity_type === 'property') promises.push(this.loadProperty(lead.entity))

        const response = await Promise.all(promises)
        this.activitiesLoading = false
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij laden van leadinformatie, probeer het opnieuw.')
      }
    },
    async fetchOwners (entity) {
      try {
        const response = this.lead?.entity_type === 'property' ? await getPropertyOwners(entity) : await getProjectOwners(entity)
        this.owners = response.data
        return response
      } catch {
        errorModal('Eigenaars kunnen niet worden geladen')
      }
    },
    async loadLead (leadId) {
      const response = await getLeadById(leadId)
      this.lead = response.data
      return response.data
    },

    async loadFollowersForLead (leadId) {
      const response = await getLeadFollowers(leadId)
      this.followers = response.data.results
      return response.data
    },

    showBreakModal () {
      this.$refs.breakModal.clearForm()
      this.$refs.breakModal.show()
    },

    hasVisitActivity () {
      return this.activities.some(activity => activity.type?.id === this.VISIT_ACTIVITY_TYPE_ID)
    },

    showPropertyBidsModal () {
      const contacts = this.lead.candidates
      this.$refs.propertyBidsModal.show(contacts)
    },

    async doBreakLead (event) {
      const payload = {
        feedback_internal: this.$refs.breakModal.feedbackInternal,
        feedback_reporting: this.$refs.breakModal.feedbackReporting
      }
      if (event.breakReason) {
        payload.break_reason = event.breakReason
      }
      await breakLead(this.lead.id, payload)
      await this.refreshLead()
      this.$refs.hmodhOpener.trigger(this.lead.id)
    },
    async refreshLead () {
      const leadResponseObj = await this.loadLead(this.lead.id)
      const response = await Promise.all([
        this.loadActivitiesForLead(leadResponseObj.id),
        this.loadProperty(this.lead.entity)
      ])
      this.activitiesLoading = false
      if (this.$refs.renterProfile) this.$refs.renterProfile.reload()
      return response
    },
    showNewActivityModal () {
      this.$refs.newActivityModal.show()
    },
    showAssignModal () {
      this.$refs.leadAssignmentModal.show()
    },
    showShareModal () {
      this.$refs.shareModal.show()
    },
    async changeStatus (status) {
      try {
        const statuses = {
          0: {
            questionText: 'Weet je zeker dat je deze lead wil verplaatsen naar de shortlist?',
            successText: 'Deze lead werd succesvol verplaatst naar de shortlist.'
          },
          1: {
            questionText: 'Weet je zeker dat je deze lead wil verplaatsen naar de longlist?',
            successText: 'Deze lead werd succesvol verplaatst naar de longlist.'
          },
          3: {
            questionText: 'Weet je zeker dat je deze lead wil aanduiden als een deal?',
            successText: 'Deze lead werd succesvol gemarkeerd als een deal.'
          },
          4: {
            questionText: 'Weet je zeker dat je deze lead wil verplaatsen naar de coldlist?',
            successText: 'Deze lead werd succesvol verplaatst naar de coldlist.'
          }
        }

        const result = await questionModal(statuses[status]?.questionText)
        if (!result.value) return

        const data = { status }
        // Contact shouldn't be set as buyer/seller/acquirer if it's an INTAKE lead
        if (
          status === 3 && this.lead?.entity_type === 'property' &&
          [this.SALES_FUNNEL_ID, this.RENTAL_FUNNEL_ID].includes(this.lead?.stage?.funnel)
        ) {
          let roleStr = ''
          // 2 for sale, 3 for rent, 4 for acquisition
          // 10 sold, 11 rented, 12 acquired
          switch (this.property?.status) {
            case 2:
              roleStr = 'koper'
              break
            case 3:
              roleStr = 'huurder'
              break
            case 4:
              roleStr = 'overnemer'
              break
            case 10:
              roleStr = 'koper'
              break
            case 11:
              roleStr = 'huurder'
              break
            case 12:
              roleStr = 'overnemer'
              break
            default:
              roleStr = 'koper'
          }
          const resultCreateRole = await questionModal(`Lead zal worden aangeduid als een deal. Wil je deze lead als ${roleStr} aan het pand koppelen?`, '')
          if (resultCreateRole.value) data.add_contact_as_buyer_or_renter = true
        }

        startLoadingModal()
        const response = await setLeadStatus(this.lead.id, data)
        await this.refreshLead()
        successModal(statuses[status]?.successText)
        return response
      } catch (error) {
        console.log(error)
        errorModal('Er ging iets fout bij het verplaatsen van deze lead')
      }
    },
    async claimLead () {
      try {
        startLoadingModal('Lead claimen...')
        const response = await assignLead(this.lead.id, { collaborator: this.$store.state.account.user.collaborator.id })
        this.claimed = true
        successModal('Deze lead is nu aan jou toegewezen')
        this.$router.push({ name: 'LeadDetails', params: { id: this.lead.id } })
        return response
      } catch (error) {
        errorModal('<p>Er ging iets mis bij het toewijzen van deze lead. Gelieve het nogmaals te proberen.</p><p><small>Deze foutmelding is doorgegeven aan IT.</small></p>')
      }
    },
    async previousStage () {
      try {
        startLoadingModal('Bezig met het verplaatsen van deze lead...')
        const response = await updateLead(this.$route.params.id, { stage: this.lead.previous_stage.id })
        await this.refreshLead()
        successModal('Deze lead is naar de vorige fase verplaatst')
        return response
      } catch (error) {
        errorModal('Er ging iets fout bij het verplaatsen van deze lead')
      }
    },
    async nextStage () {
      try {
        startLoadingModal('Bezig met het verplaatsen van deze lead...')
        const response = await updateLead(this.$route.params.id, { stage: this.lead.next_stage.id })
        await this.refreshLead()
        successModal('Deze lead is naar de volgende fase verplaatst')
        return response
      } catch (error) {
        errorModal('Er ging iets fout bij het verplaatsen van deze lead')
      }
    },
    /** Open the Confirmation model to send a confirmation to the candidate that want to notify **/
    sendConfirmation (payload) {
      if (payload.type === 'SMS') {
        this.$refs.smsModal.activity = payload.activity
        this.$refs.smsModal.show()
      } else if (payload.type === 'Email') {
        EventBus.$emit('sales-confirm-activity', payload.activity.match, payload.activity.id)
      }
    },
    finishActivity (activity) {
      this.$refs.feedbackModal.clearForm()
      this.feedbackModalTitle = 'Activiteit afpunten'
      this.activity = activity
      this.$refs.feedbackModal.show()
    },
    reloadActivities () {
      this.activitiesLoading = true
      const response = this.loadActivitiesForLead(this.lead.id)
      this.activitiesLoading = false
      return response
    },
    /**
       * Triggered when an activity is removed by the ActivityTimeLineItem component
       **/
    activityRemoved (activity) {
      this.loadActivitiesForLead(this.lead.id).then(() => {
        this.activitiesLoading = false
      })
    },
    /**
       * Triggered when an activity is added or updated
       */
    activityChanged () {
      this.loadActivitiesForLead(this.lead.id).then(() => {
        this.activitiesLoading = false
      })
      this.refreshLead()
    },
    /**
       * Triggered when an activity is finished using the ActivityFeedbackModal
       * @param activity Activity that was finished
       */
    async activityFinished (activity, assignLead) {
      this.$refs.feedbackModal.hide()
      await this.loadLead(this.lead.id)
      let type = null
      if (typeof activity.type === 'object') {
        type = activity.type.id
      } else {
        type = activity.type
      }
      if (!(this.lead.stage?.funnel === 3 && type === 2) && this.lead.status !== 2) { // status 2 = Break
        this.$refs.newActivityModal.show()
      }
      this.loadActivitiesForLead(this.lead.id).then(() => {
        this.activitiesLoading = false
      })
      this.$refs.hmodhOpener.trigger(this.lead.id)
    },
    userImage (collaborator) {
      return collaborator.picture || ProfileImg
    },
    async shareWithCollaborator (collaborator) {
      try {
        startLoadingModal('Bezig met het delen van deze lead...')
        const payload = { lead: this.lead, collaborator }
        const response = await this.shareLead(payload)
        await this.refreshLead()
        this.$refs.shareModal.hide()
        successModal('Deze lead is gedeeld met jouw collega.')
        return response
      } catch (error) {
        console.error(error)
        errorModal('<p>Er ging iets mis bij het delen van deze lead. Gelieve het nogmaals te proberen.</p><p><small>Deze foutmelding is doorgegeven aan IT.</small></p>')
      }
    },

    /** When property is of type prospect retrieve an edit config without Candidate editing, else allow it */
    getConfig () {
      const config = this.editConfig
      const prospectStatus = 1
      // Shift removes the candidate field from the intake form. Propsects are not allowed to set candidates
      if (this.property?.status === prospectStatus) config.fields.shift()
      return config
    },
    editLead () {
      this.activeEditConfig = this.getConfig()
      this.$refs.editModal.show()
    },
    async removeLead () {
      try {
        const result = await questionModal('Weet je zeker dat je deze lead wil verwijderen?')
        if (!result.value) return

        startLoadingModal('Bezig met het verwijderen van deze lead...')
        const response = await deleteLead(this.lead.id)
        successModal('Deze lead is verwijderd')
        this.$router.push('/sales?vc=0')
        return response
      } catch (error) {
        let errorMessage = '<p>Er ging iets mis bij het verwijderen van deze lead. Gelieve het nogmaals te proberen.</p><p><small>Deze foutmelding is doorgegeven aan IT.</small></p>'
        if (error.response.status === 406) errorMessage = error.response.data?.reason
        errorModal(errorMessage)
      }
    },
    async handleSave (values) {
      try {
        startLoadingModal('Wijzigingen worden doorgevoerd.')
        const data = { ...values }
        if (data.candidates) data.candidate_contact_ids = data.candidates.map(contact => contact.id)
        const response = await updateLead(this.lead.id, data)
        await this.refreshLead()
        this.$refs.editModal.hide()
        successModal('De wijzigingen zijn doorgevoerd')
        return response
      } catch (error) {
        errorModal('Er ging iets fout bij het wijzigen van dit pand. Gelieve nogmaals te proberen.')
      }
    },

    async unfollowLead (followerId) {
      try {
        const result = await questionModal('Weet je zeker dat je deze lead niet meer wilt volgen?')
        if (!result.value) return

        startLoadingModal('De lead ontvolgen...')
        const payload = { follower_id_list: [followerId] }
        const response = await removeLeadFollower(this.lead.id, payload)
        await this.loadFollowersForLead(this.lead.id)
        successModal('Lead succesvol ontvolgd')
        return response
      } catch (error) {
        errorModal('Fout bij het ontvolgen van de lead. Probeer het opnieuw.')
      }
    },
    /* This method collapses or expands all activiies */
    toggleAllActivityDetails () {
      this.showDetails = !this.showDetails
      this.$refs.activityItems.forEach(activityItem => {
        activityItem.setShowDetails(this.showDetails)
      })
    }
  }
}
</script>

<style scoped>
  /* When viewing on a small screen we fix the display of the 'Verberg details' button */
  @media only screen and (max-width: 768px) {
    .timeline-contents {
      margin-top: 30px;
    }

    .btn-toggle-details {
      float: left;
    }
  }
</style>
